import { Routes, Route } from "react-router-dom";

// Navigations URL import constants
import { equipment, login, orderHistory } from "./navigation/navigationConst";

import "./App.css";
import HomeScreen from "../src/pages/HomeScreen";
import Cart from "../src/pages/Cart";
import Login from "./pages/Login";
import Dashboard from "./pages/common/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import EquipmentMaster from "./pages/EquipmentMaster";
import OrderHistoryMaster from "./pages/OrderHistoryMaster";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import AuthInit from "./AuthInit";
import Auth from "./pages/Auth";
import SearchCatalogue from "./pages/SearchCatalogue";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";

function App() {
  const { auth } = useContext(AuthContext);
  console.log(auth.didTryAutoLogin);
  return (
    <div id="main-wrapper" className="show">
      {!auth.didTryAutoLogin ? <AuthInit /> :
        <Routes>

          <Route path={login} element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Dashboard />}>
            <Route index element={<HomeScreen />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/search" element={<SearchCatalogue />} />

            <Route path={equipment} element={<EquipmentMaster />} />
            <Route path={orderHistory} element={<OrderHistoryMaster />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      }
    </div>
  );
}

export default App;