import React, { createContext, useReducer, useMemo } from "react";
import authReducer, { initialState } from '../reducers/AuthReducer';
import { KEYS, LocalStorageActions } from "../services/LocalData";
import Cookies from 'js-cookie';
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [auth, dispatch] = useReducer(authReducer, initialState);
    const authActions = useMemo(() => ({
        //For successful Signin
        signIn: async data => {
            //Set the auth data in context
            dispatch({
                actionType: 'LOGIN',
                accessToken: data.accessToken,
                userId: data.userId,
                role: data.role,
                userEmail: data.userEmail,
                tenantId: data.tenantId,
                planName: data.planName
            });

            // Store Auth Data in local storage
            LocalStorageActions.store(KEYS.USER_DATA,
                JSON.stringify({
                    accessToken: data.accessToken,
                    role: data.role,
                    userId: data.userId,
                    userEmail: data.userEmail,
                    tenantId: data.tenantId,
                    planName: data.planName
                }));
            var cookiesData = {
                user_tenantId: data.tenantId,
                user_userId: data.userId,
                user_userEmail: data.userEmail,
                user_accessToken: data.accessToken,
                access_token: data.accessToken ? `Bearer ${data.accessToken}` : '',
                user_roles: data.role,
                user_planName: data.planName,
                user_logIn_Status: true
            }
            var loginCookieData = JSON.stringify(cookiesData);
            Cookies.set('logincustomerinfo', loginCookieData);
        },
        //For successful auto login
        restore: async data => {
            dispatch({
                actionType: 'LOGIN',
                accessToken: data.accessToken,
                userId: data.userId,
                role: data.role,
                userEmail: data.userEmail,
                tenantId: data.tenantId,
                planName: data.planName
            });
        },

        //Set this state, to route the flow to Login page 
        didTryAutoLogin: () => dispatch({ actionType: "DID_TRY_AUTO_LOGIN" }),
        //For signout flow
        signOut: () => {
            dispatch({ actionType: 'LOGOUT' });
            LocalStorageActions.removeAll(KEYS.USER_DATA);
            Cookies.remove("logincustomerinfo");
        }
    }), []);


    return (
        <AuthContext.Provider value={{ auth, authActions }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;