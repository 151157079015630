import { Box, Divider, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from '@mui/icons-material/Send';

export default function PortfolioSearchResult(props) {
    let { result, setSelectedPortfolio } = props;
    return (

        <Box
            sx={{
                border: 1,
                borderRadius: 3,
                borderColor: "lightgrey",
                minHeight: 300,
                maxHeight: 400,
                overflow: "hidden",
                overflowY: "auto",
                overflowClipMargin: '10px',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                    maxHeight: '0.8em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                    borderRadius: 10,
                    backgroundColor: 'rgba(0,0,0,.6)',
                    outline: '1px solid slategrey'
                }
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{ fontSize: 17, color: "grey", my: 1, paddingLeft: 1 }}
                    >
                        Search Results
                    </Typography>
                    <Divider />
                    {result?.length > 0 ? result.map((indPortfolio) => (
                        <Grid container sx={{ padding: 1 }}>
                            <Grid item container xs={9}>
                                <AddCircleOutlineIcon
                                    sx={{
                                        fontSize: 40,
                                        color: "#87b8ef",
                                        marginRight: 2,
                                    }}
                                />
                                <Typography sx={{ fontSize: 16 }}>
                                    {indPortfolio.name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <a
                                    href={undefined}
                                    onClick={() => setSelectedPortfolio(indPortfolio)}
                                >
                                    <Typography
                                        mx={2}
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: '#00000090',
                                            ":hover": { color: "#872ff7", cursor: "pointer" },
                                        }}
                                    >
                                        DETAILS <SendIcon sx={{ mx: 2, }} />
                                    </Typography>
                                </a>
                            </Grid>
                            <Divider />
                        </Grid>
                    )) : <Grid container sx={{ padding: 1 }}>
                        <Grid item container xs={12} justifyContent={'center'}>
                            <Typography fontSize={17} fontWeight={500} color={'Grey'}>No Results Found</Typography>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Box>


    )
}