import axios from "axios";
import { BASE_URL, USER_SERVICE_FORGOT_PASSWORD, USER_SERVICE_RESET_PASSWORD } from "./CONSTANTS";
import { SYSTEM_ERROR } from "../config/CONSTANTS";

//User Login
export const userLogin = (data) => {
    console.log("service Commerce > Update account called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(`${BASE_URL}user-svc/v1/user/root-login`, data)
                .then((res) => {
                    console.log("Login response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("Login > axios err=", err);
                    reject("Error in update account axios!");
                });
        } catch (error) {
            console.error("Login general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * API to send email for forgot password
 */
export const forgotPassword = (data) => {
    console.log("userServices > forgotPassword called...");
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            axios
                .post(USER_SERVICE_FORGOT_PASSWORD(), data)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                    console.log("forgotPassword > axios res=", res);

                })
                .catch((err) => {
                    console.log("forgotPassword > axios err=", err);
                    reject("Error in forgotPassword axios!");
                });
        } catch (error) {
            console.error("in userServices > forgotPassword, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


/**
 * API to reset password
 */
export const resetPassword = (data) => {
    console.log("userServices > resetPassword called...");
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            axios
                .post(USER_SERVICE_RESET_PASSWORD(), data)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                    console.log("resetPassword > axios res=", res);

                })
                .catch((err) => {
                    console.log("resetPassword > axios err=", err);
                    reject("Error in resetPassword axios!");
                });
        } catch (error) {
            console.error("in userServices > resetPassword, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};