import React, { useState } from "react";
import DataGridContainer from "./common/DataGridContainer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ViewOrderProfile from "./ViewOrderProfile";

const OrderHistoryMaster = () => {
  const [viewPlacedOrder, setViewPlacedOrder] = useState(false);

  const dispatchedOrderColumns = [
    { field: "order", headerName: "Order/Quote#", flex: 1, minWidth: 120 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <span
          className={`dispatched-order-status ${
            params.value.toLowerCase() === "reviewed"
              ? "dispatch-reviewed-order"
              : params.value.toLowerCase() === "in revision"
              ? "dispatch-revision-order"
              : "dispatch-approved-order"
          }`}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "serviceName",
      headerName: "Product/Service Name",
      flex: 1,
      minWidth: 220,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "quoteDate",
      headerName: "Order/Quote Date",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => setViewPlacedOrder(true)}>
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];
  const dispatchData = [
    {
      id: 0,
      order: "QT000001",
      status: "Approved",
      quoteDate: "29-04-2023",
      price: "90000",
      serviceName: "EW6000",
    },
    {
      id: 1,
      order: "QT000002",
      status: "In Revision",
      quoteDate: "29-04-2023",
      price: "50020",
      serviceName: "EW6000",
    },
    {
      id: 2,
      order: "QT000003",
      status: "Reviewed",
      quoteDate: "29-04-2023",
      price: "50000",
      serviceName: "EW6000",
    },
  ];
  return (
    <>
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h4 className="font-weight-600 mb-0 mt-4">Quote History</h4>
          <DataGridContainer
            rowsData={dispatchData}
            columns={dispatchedOrderColumns}
            orderData={true}
          />
        </div>
      </div>
      {viewPlacedOrder && (
        <ViewOrderProfile
          show={viewPlacedOrder}
          hideModal={() => setViewPlacedOrder(false)}
        />
      )}
    </>
  );
};

export default OrderHistoryMaster;
