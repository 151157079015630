import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import { BASE_URL, DATA_SVC_EQUIPMENT } from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
    let storedCookie = Cookies.get("logincustomerinfo");
    console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
    let getCookiesJsonData;
    if (storedCookie != undefined) {
        getCookiesJsonData = JSON.parse(storedCookie);
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${getCookiesJsonData?.access_token}`,
        },
    };
    return config;
}

//Fetch Equipments
export const fetchEquipments = (searchString) => {
    console.log("service Commerce > Fetch Accounts Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DATA_SVC_EQUIPMENT() + `/search?search=${searchString}`, getAccessToken())
                .then((res) => {
                    console.log("EquipmentService -> Fetch Equipments response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetchEquipments > axios err=", err);
                    reject("Error in fetchEquipments axios!");
                });
        } catch (error) {
            console.error("fetchEquipments general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};


//Fetch Usages
export const fetchUsage = (equipmentNumber) => {
    console.log("service Commerce > Fetch Usage Called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DATA_SVC_EQUIPMENT() + `/${equipmentNumber}/usage`, getAccessToken())
                .then((res) => {
                    console.log("EquipmentService -> Fetch Equipment usages response: ", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.error);
                    }
                })
                .catch((err) => {
                    console.log("fetchEquipments usages > axios err=", err);
                    reject("Error in fetchEquipments usages axios!");
                });
        } catch (error) {
            console.error("fetchEquipments usages general exception", error);
            reject(SYSTEM_ERROR);
        }
    });
};