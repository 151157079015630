import React, { useContext } from "react";
import Header from "../../shared/Header";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Login from "../Login";

const Dashboard = () => {
  const { auth } = useContext(AuthContext);

  return auth.accessToken ? (
    <>
      <Header />
      <SideBar />
      <Outlet />
    </>
  ) : (
    <Login />
  );
};

export default Dashboard;
