import { Modal } from "react-bootstrap";
import PortfolioConfig from "./PortfolioConfig";

export default function PortfolioConfigModal(props) {
    const { show, handleClose, setSelectedPortfolio, handleSnack, selectedPortfolio } = props;
    return (
        <Modal
            className="tablerowmodal"
            show={show}
            onHide={() => handleClose()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body className="">
                <PortfolioConfig
                    selectedPortfolio={selectedPortfolio}
                    setSelectedPortfolio={setSelectedPortfolio}
                    handleSnack={handleSnack}
                    showBackbutton={false}
                />
            </Modal.Body>
        </Modal>
    )
}