import { useState } from "react";
import { RenderConfirmDialog } from "../components/ConfirmationBox";
import NumberSpinner from "../shared/NumberSpinner";
import { Card, Typography } from "@mui/material";
import CartItemEditModal from "./CartItemEditModal";
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
export default function CartItem(props) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { removeItem, setCartItems, item, handleSnack } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  return (
    <div>
      <RenderConfirmDialog
        confimationOpen={confirmationOpen}
        message={`Pressing 'Yes' will remove the cart item!`}
        handleNo={() => setConfirmationOpen(false)}
        handleYes={() =>
          removeItem(item.portfolioCart.portfolioCartId)
        }
      />
      <CartItemEditModal show={show} handleClose={handleClose} item={item} setItem={setCartItems}
        handleSnack={handleSnack}
      />
      <Card
        elevation={2}
        sx={{
          p: 2,
          backgroundColor: "#f5f5f5",
          borderRadius: 3,
          borderColor: " #872ff7 ",
        }}
        variant="outlined"
      >
        <div className="row" style={{ alignItems: "center" }}>
          {/* <div className="col-md-3">
                <Card sx={{borderRadius: 3}}><img src="./assets/images/jcb-sm.png" className="w-100"></img></Card>
          </div> */}
          <div className="row col-md-12 ml-2">
            <div className="col-md-9">
              <Typography fontSize={16} fontWeight={500}>
                {item.name}
              </Typography>
              <Typography fontSize={13} className="mb-0 mt-2">
                {item.description}
              </Typography>
            </div>
            <div className="col-md-3">
              <Typography variant="h6">
                {formatter.format(
                  item.portfolioPrice.totalPrice *
                  item.portfolioCart.quantity
                )}
              </Typography>
            </div>
            <div className="row col-md-12 mt-4">
              <div className="col-sm-6 col-md-2">
                {" "}
                {/* <h5 className="mb-0">
                  {props.item.portfolioCart.quantity}
                </h5> */}
                <NumberSpinner
                  portfolioCartId={item.portfolioCart.portfolioCartId}
                  qnt={item.portfolioCart.quantity}
                  setCartItems={setCartItems}
                  steps={1}
                  setConfirmationOpen={setConfirmationOpen}
                />
              </div>
              <div className="col-md-2 col-sm-6">
                <button
                  onClick={() => setConfirmationOpen(true)}
                  className="btn bg-primary text-white remove-button"
                  style={{ fontSize: 12, height: 26 }}
                >
                  Remove
                </button>
              </div>
              <div className="col-md-2 col-sm-6">
                <button
                  onClick={() => setShow(true)}
                  className="btn bg-primary text-white remove-button"
                  style={{ fontSize: 12, height: 26 }}
                >
                  Configure
                </button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
