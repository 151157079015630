import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import { BASE_URL } from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}
//Add to cart
export const addToCart = (id, queryParam, data) => {
  console.log("service Commerce > AddToCart called...");
  return new Promise((resolve, reject) => {
    console.log("Query Param", queryParam);
    try {
      axios
        .post(`${BASE_URL}commerce-svc/v1/portfolio/addToCart/${id}` + queryParam, data, getAccessToken())
        .then((res) => {
          console.log("Commerce > AddToCart  response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("AddToCart > axios err=", err);
          reject("Error in AddtoCart axios!");
        });
    } catch (error) {
      console.error("AddToCart general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Update Cart
export const UpdateCart = (id, data) => {
  console.log("service Commerce > Update Cart called...");
  return new Promise((resolve, reject) => {
    console.log(getAccessToken());
    try {
      axios
        .put(`${BASE_URL}commerce-svc/v1/portfolio/updateCart/${id}`, data, getAccessToken())
        .then((res) => {
          console.log("Portfolio Service -> Update Cart response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("CreateBuilder > axios err=", err);
          reject("Error in CreateBuilder axios!");
        });
    } catch (error) {
      console.error("CreateBuilder general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Remove Cart Item
export const removeCart = (id) => {
  console.log("service Commerce > delete Cart called...");
  return new Promise((resolve, reject) => {
    console.log(getAccessToken());
    try {
      axios
        .delete(`${BASE_URL}commerce-svc/v1/portfolio/deleteCart/${id}`, getAccessToken())
        .then((res) => {
          console.log("Portfolio Service -> delete Cart response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("CreateBuilder > axios err=", err);
          reject("Error in CreateBuilder axios!");
        });
    } catch (error) {
      console.error("CreateBuilder general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Get Cart portfolios
export const getCartPortfolios = (id) => {
  console.log("service commerce > getCartPortfolio called...");
  return new Promise((resolve, reject) => {
    console.log(getAccessToken());
    try {
      axios
        .get(`${BASE_URL}commerce-svc/v1/portfolio/getCartPortfolios`, getAccessToken())
        .then((res) => {
          console.log("commmerce -> getCartPortfolio response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("getCartPortfolio > axios err=", err);
          reject("Error in getCartPortfolio axios!");
        });
    } catch (error) {
      console.error("getCartPortfolio general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};


//Get portfolios
export const getPortfolios = (id) => {
  console.log("service commerce > getPortfolios called...");
  return new Promise((resolve, reject) => {
    console.log(getAccessToken());
    try {
      axios
        .get(`${BASE_URL}commerce-svc/v1/portfolio/getPortfolios`, getAccessToken())
        .then((res) => {
          console.log("commmerce -> getPortfolios response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("getPortfolios > axios err=", err);
          reject("Error in getPortfolios axios!");
        });
    } catch (error) {
      console.error("getPortfolios general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};


//Get portfolios
export const getPortfolioSearch = (data) => {
  console.log("service commerce > getPortfolioSearch called...");
  return new Promise((resolve, reject) => {
    console.log(getAccessToken());
    try {
      axios
        .post(`${BASE_URL}commerce-svc/v1/portfolio/search?pageNum=0&pageSize=10`, data, getAccessToken())
        .then((res) => {
          console.log("commmerce -> getPortfolioSearch response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("getPortfolioSearch > axios err=", err);
          reject("Error in getPortfolioSearch axios!");
        });
    } catch (error) {
      console.error("getPortfolioSearch general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const fetchCoverageSuggestions = (data) => {
  console.log("service commerce > getCoverageParam called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_URL}commerce-svc/v1/portfolio/getCoverageParamList?pageNum=0&pageSize=10`, data, getAccessToken())
        .then((res) => {
          console.log("commmerce -> getCoverageParam response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("getCoverageParam > axios err=", err);
          reject("Error in getCoverageParam axios!");
        });
    } catch (error) {
      console.error("getCoverageParam general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
}