import { NavLink } from "react-router-dom";
const SideBar = () => {
  return (
    <div>
      <nav className="main-menu">
        <ul className="metismenu scrollbamenu" id="menu">
          <li className="has-subnav sub-li">
            <NavLink
              to="/"
              activeclassname="active"
              className="border-radius-50"
            >
              <span className="span-icon home">
                <svg
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 27.3"
                >
                  <g>
                    <g>
                      <path
                        className="st1"
                        d="M1.5,18.7c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L15.2,1.4c0.9-0.9,2-1.4,3.3-1.4
                              c1.2,0,2.4,0.5,3.3,1.4l14.8,14.8c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0L19.6,3.5c-0.6-0.6-1.7-0.6-2.3,0L2.6,18.3
                              C2.3,18.6,1.9,18.7,1.5,18.7z"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M18.5,27.3c-5,0-9.1-4.1-9.1-9.1c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,3.4,2.8,6.1,6.1,6.1
                              s6.1-2.8,6.1-6.1c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C27.6,23.2,23.5,27.3,18.5,27.3z"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M31.6,13.8c-0.8,0-1.5-0.7-1.5-1.5V4.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v7.9
                              C33.1,13.2,32.4,13.8,31.6,13.8z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span className="nav-text">
                <span className="ml-2 " style={{ display: "inline-grid" }}>
                  <span className="font-size-13 font-weight-400 text-dark">
                    Home
                  </span>
                </span>
              </span>
            </NavLink>
          </li>
          <li className="has-subnav sub-li">
            <NavLink
              to="/equipment"
              activeclassname="active"
              className="border-radius-50"
            >
              <span className="span-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 297 297"
                  // height={13}
                  // width={13}
                >
                  <path
                    d="M211.357,71.437c1.261,0.216,2.523,0.322,3.777,0.322c4.534,0,8.949-1.396,12.725-4.068
		c4.82-3.412,8.023-8.497,9.019-14.317c0.996-5.821-0.335-11.682-3.746-16.502c-3.412-4.82-8.497-8.023-14.317-9.019
		c-5.822-0.997-11.681,0.336-16.502,3.746c-4.82,3.412-8.023,8.497-9.019,14.317c-0.996,5.821,0.335,11.682,3.746,16.502
		C200.452,67.238,205.537,70.441,211.357,71.437z M208.154,48.459C208.154,48.458,208.154,48.458,208.154,48.459
		c0.317-1.853,1.336-3.471,2.87-4.556c1.2-0.85,2.605-1.294,4.047-1.294c0.398,0,0.8,0.033,1.201,0.102
		c1.852,0.317,3.47,1.336,4.555,2.87c1.086,1.533,1.509,3.396,1.192,5.248s-1.336,3.47-2.87,4.555
		c-1.532,1.085-3.393,1.51-5.248,1.192c-1.852-0.317-3.47-1.336-4.555-2.87C208.26,52.174,207.837,50.311,208.154,48.459z"
                  />
                  <path
                    d="M98.749,117.164c13.301,0,24.122-10.821,24.122-24.122S112.049,68.92,98.749,68.92S74.627,79.741,74.627,93.042
		S85.448,117.164,98.749,117.164z M98.749,83.996c4.988,0,9.046,4.058,9.046,9.046s-4.058,9.046-9.046,9.046
		s-9.046-4.058-9.046-9.046S93.761,83.996,98.749,83.996z"
                  />
                  <path
                    d="M289.462,243.05h-42.213v-42.213c0-4.164-3.374-7.538-7.538-7.538h-33.168c-4.164,0-7.538,3.374-7.538,7.538v42.213
		h-67.843V129.741c0.687-0.607,1.36-1.229,2.012-1.873l68.602-25.273l1.763,32.317c0.219,3.998,3.524,7.128,7.527,7.128h4.523
		v25.629c0,4.164,3.374,7.538,7.538,7.538s7.538-3.374,7.538-7.538V142.04h5.528c4.039,0,7.36-3.183,7.531-7.218l2.234-52.662
		c3.815-3.74,6.966-8.174,9.272-13.17c4.989-10.811,5.471-22.918,1.355-34.091c-4.117-11.173-12.337-20.074-23.147-25.064
		c-10.809-4.99-22.917-5.471-34.09-1.355l-96.95,35.717c-1.207-0.089-2.421-0.15-3.65-0.15c-27.017,0-48.997,21.98-48.997,48.997
		c0,7.919,1.9,15.399,5.251,22.026L18.436,243.05H7.538c-4.164,0-7.538,3.374-7.538,7.538v33.168c0,4.164,3.374,7.538,7.538,7.538
		h281.924c4.164,0,7.538-3.374,7.538-7.538v-33.168C297,246.424,293.626,243.05,289.462,243.05z M214.081,208.375h18.091v34.675
		h-18.091V208.375z M228.968,126.963h-10.763l-1.627-29.821l13.589-5.006c0.096-0.035,0.188-0.077,0.284-0.112L228.968,126.963z
		 M204.56,22.626c7.392-2.724,15.404-2.407,22.56,0.897c7.154,3.302,12.594,9.193,15.318,16.587
		c2.725,7.394,2.406,15.406-0.897,22.56c-3.302,7.155-9.193,12.595-16.587,15.319l-79.303,29.215
		c1.357-4.486,2.095-9.239,2.095-14.162c0-17.265-8.984-32.462-22.515-41.191L204.56,22.626z M98.749,59.121
		c18.704,0,33.921,15.217,33.921,33.921s-15.217,33.921-33.921,33.921s-33.921-15.217-33.921-33.921S80.045,59.121,98.749,59.121z
		 M66.459,129.849c8.63,7.58,19.928,12.191,32.29,12.191c6.103,0,11.944-1.131,17.338-3.179V243.05H34.116L66.459,129.849z
		 M281.924,276.217H15.076v-18.091h266.848V276.217z"
                  />
                  <path
                    d="M210.096,176.731c1.963-1.962,1.963-5.144,0-7.106l-13.019-13.019c-1.961-1.963-5.145-1.963-7.106,0
		c-1.963,1.962-1.963,5.144,0,7.106l13.019,13.019c0.981,0.982,2.267,1.472,3.553,1.472
		C207.829,178.204,209.116,177.713,210.096,176.731z"
                  />
                  <path
                    d="M198.455,179.199h-13.019c-2.776,0-5.025,2.25-5.025,5.025s2.25,5.025,5.025,5.025h13.019c2.776,0,5.025-2.25,5.025-5.025
		S201.231,179.199,198.455,179.199z"
                  />
                  <path
                    d="M256.283,156.606c-1.961-1.963-5.145-1.963-7.106,0l-13.019,13.019c-1.963,1.962-1.963,5.144,0,7.106
		c0.981,0.982,2.267,1.472,3.553,1.472c1.286,0,2.573-0.491,3.553-1.472l13.019-13.019
		C258.246,161.75,258.246,158.568,256.283,156.606z"
                  />
                  <path
                    d="M242.773,184.224c0,2.776,2.25,5.025,5.025,5.025h13.019c2.776,0,5.025-2.25,5.025-5.025s-2.25-5.025-5.025-5.025h-13.019
		C245.023,179.199,242.773,181.449,242.773,184.224z"
                  />
                </svg>
              </span>
              <span className="nav-text">
                <span className="ml-2 " style={{ display: "inline-grid" }}>
                  <span className="font-size-13 font-weight-400 text-dark">
                    Equipment
                  </span>
                </span>
              </span>
            </NavLink>
          </li>
          <li className="has-subnav sub-li">
            <NavLink
              to="/search"
              activeclassname="active"
              className="border-radius-50"
            >
              <span className="span-icon">
                <svg
                  id="uuid-c05174b0-733e-42a0-8a41-26f820ee4aba"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 107.79136 107.79136"
                >
                  <path
                    className="uuid-aedeb60b-b298-4c08-abdd-280bb25795c7"
                    d="M45.363-.00007C43.06099-.00007,41.19551,1.86565,41.19551,4.16741v13.09741C18.12935,19.37664-.00004,38.82024-.00004,62.42766-.00004,87.4406,20.34981,107.79143,45.363,107.79143c23.60718,0,43.05151-18.13037,45.16357-41.19629h13.09741c2.30176,0,4.16748-1.86548,4.16748-4.16748C107.79146,28.00506,79.78609-.00007,45.363-.00007Zm0,99.45654c-20.41748,0-37.02808-16.61133-37.02808-37.02881,0-19.00806,14.39868-34.70825,32.8606-36.78638V62.42766c0,2.302,1.86548,4.16748,4.16748,4.16748h36.78711c-2.07837,18.46191-17.7793,32.86133-36.78711,32.86133Zm4.16748-41.19629V8.49456c26.51245,2.02832,47.73804,23.25317,49.76733,49.76562H49.53048Z"
                  />
                </svg>
              </span>
              <span className="nav-text">
                <span className="ml-2 " style={{ display: "inline-grid" }}>
                  <span className="font-size-13 font-weight-400 text-dark">
                    Search Catalogue
                  </span>
                </span>
              </span>
            </NavLink>
          </li>
          <li className="has-subnav sub-li">
            <NavLink
              to="/order-history"
              activeclassname="active"
              className="border-radius-50"
            >
              <span className="span-icon">
                <svg
                  id="uuid-44f21729-ee7f-4ae1-a13f-50d052182172"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37.7 35.5"
                >
                  <path
                    d="M7.8,35.5C3.5,35.5,0,32,0,27.7c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3c0,2.9,2.4,5.3,5.3,5.3
		c2.9,0,5.2-2.3,5.3-5.1c0-0.8,0.7-1.5,1.5-1.5h21.7c0.7,0,1.3,0.6,1.3,1.3S37.1,29,36.4,29H15.6C15,32.7,11.8,35.5,7.8,35.5z
		 M15.7,28C15.7,28,15.7,28,15.7,28C15.7,28,15.7,28,15.7,28z"
                  />
                  <path
                    d="M29.8,35.5c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c2.9,0,5.3-2.4,5.3-5.3c0-0.7,0.6-1.3,1.3-1.3
		s1.3,0.6,1.3,1.3C37.7,32,34.2,35.5,29.8,35.5z"
                  />
                  <path
                    d="M1.3,29C0.6,29,0,28.4,0,27.7V4h31.6v19c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3V6.5H2.5v21.2
		C2.5,28.4,1.9,29,1.3,29z"
                  />
                  <path d="M23.3,12.6H7.6c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h15.7c0.7,0,1.3,0.6,1.3,1.3S24,12.6,23.3,12.6z" />
                  <path d="M15.4,17.9H7.6c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h7.9c0.7,0,1.3,0.6,1.3,1.3S16.1,17.9,15.4,17.9z" />
                  <path d="M24,17.9h-3.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3H24c0.7,0,1.3,0.6,1.3,1.3S24.7,17.9,24,17.9z" />
                  <path d="M23.3,23.1H7.6c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h15.7c0.7,0,1.3,0.6,1.3,1.3S24,23.1,23.3,23.1z" />
                  <path d="M29.8,35.5H8c-0.7,0-1.3-0.6-1.3-1.3S7.3,33,8,33h21.8c0.7,0,1.3,0.6,1.3,1.3S30.5,35.5,29.8,35.5z" />
                  <path d="M7.6,8.4c-0.7,0-1.3-0.6-1.3-1.3V1.3C6.3,0.6,6.9,0,7.6,0s1.3,0.6,1.3,1.3v5.9C8.8,7.9,8.2,8.4,7.6,8.4z" />
                </svg>
              </span>
              <span className="nav-text">
                <span className="ml-2 " style={{ display: "inline-grid" }}>
                  <span className="font-size-13 font-weight-400 text-dark">
                    Quote History
                  </span>
                </span>
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default SideBar;
