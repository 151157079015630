export const PARTS_ONLY = "partsOnly";
export const SERVICE_ONLY = "serviceOnly";
export const WITH_PARTS = "withParts";
export const CATEGORIES = [
    {
        value: 'CONTRACT_OR_EXTENDED_WARRANTY',
        label: "Maintenance Solutions",
        description: "Our self-service option helps your customers browse products, aftermarket services and solution online."
    },
    {
        value: 'REPAIR_OR_REPLACE',
        label: "Repair Solutions",
        description: "Our platform is built to create templates and kits. It has twin builder functionality."
    },
    {
        value: 'ROUTINE_MAINTENANCE_OR_TASK',
        label: "Routine Maintenance Tasks",
        description: "Our inbuilt price engine helps you price spare parts, labor, miscellaneous, and consumables."
    },
    {
        value: 'NEW_EQUIPMENT_SOLUTION',
        label: "New Equipment Customisation",
        description: "The quotes and follow up orders that your sales reps build using our preconfigured solution."
    }
]
export const SUPPORT_PLANS = [
    { value: 'STANDARD', label: "STANDARD" },
    { value: 'PREMIUM', label: "PREMIUM" },
    { value: 'SUPERIOR', label: "SUPERIOR" },
    { value: 'CUSTOMIZED', label: "CUSTOMIZED" }
]
export const OWL_CAROUSEL_OPTIONS = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        800: {
            items: 3,
        },
        1200: {
            items: 4,

        }
    },
};