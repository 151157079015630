import { Modal } from "react-bootstrap";
import { Box, Card, Divider, Typography } from "@mui/material";
import { useState } from "react";
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});
export default function QuoteModal(props) {
    const { show, handleClose, cartItems, handleSnack } = props;
    const { description, setDescription } = useState("");
    const { note, setNote } = useState("");
    const handleCreateQuote = () => {
        handleSnack("success", "Converted to Quote")
    }
    return (
        <Modal
            className="tablerowmodal"
            show={show}
            onHide={() => handleClose()}
            size="md"
        >
            <Modal.Body className="">
                <Card variant="outlined" sx={{ margin: 2, padding: 2 }}>
                    <h4 className="my-2">Quote Details</h4>
                    <Divider />
                    <label className="text-light-dark font-size-15 font-weight-600 mt-2">
                        Quote Items</label>
                    <Box sx={{ m: 2 }}>
                        {cartItems &&
                            cartItems.map((item) => (
                                <div className="row">
                                    <div className="col-md-8">
                                        <Typography fontSize={16} >
                                            {item.name}
                                        </Typography>
                                    </div>
                                    <div className="row col-md-4 justify-content-end">
                                        <Typography variant="body1">
                                            {formatter.format(
                                                item.portfolioPrice.totalPrice *
                                                item.portfolioCart.quantity
                                            )}
                                        </Typography>
                                    </div>
                                </div>))}
                    </Box>
                    <div className="form-group">
                        <label className="text-light-dark font-size-15 font-weight-600">
                            Quote Description</label>
                        <input
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            size="40"
                            class="form-control border-radius-10 text-input mb-2"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-light-dark font-size-15 font-weight-600"
                            style={{ marginBlock: 8 }}>Note</label>
                        <textarea
                            onChange={(e) => setNote(e.target.value)}
                            value={note}
                            size="40"
                            class="form-control border-radius-10 text-input mb-2"
                        />
                    </div>
                    <div className="row my-3">
                        <div className="col-md-6 col-sm-6">
                            <a
                                href="/Order"
                                className="btn border-primary text-primary pull-right"
                                style={{ width: '100%' }}
                            >
                                CANCEL
                            </a>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <a
                                href={undefined}
                                className="btn bg-primary text-white pull-right"
                                style={{ width: '100%' }}
                                onClick={() => handleCreateQuote()}
                            >
                                CREATE QUOTE
                            </a>
                        </div>
                    </div>
                </Card>
            </Modal.Body>
        </Modal >
    )
}