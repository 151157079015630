
export const KEYS = {
    INTRO_STATUS: "@intro_status",
    USER_DATA: "@user_data",
};

const retrieve = (key) => {

    try {
        validateKey(key);

        let valueString = localStorage.getItem(key);

        if (valueString) {
            return JSON.parse(valueString);
        }
    } catch (error) {
        // console.log(`retrieve(${key}) failed with error: `, error);
    }

    return null;
}

const store = (key, value) => {

    // console.log("localStore.store: ", key, value);
    
    try {
        validateKey(key);

        // console.log("setItem Executing...");

        localStorage.setItem(key, JSON.stringify(value));

        // console.log("setItem success!");

        return true;
    } catch (error) {
        // console.log(`store(${key}, ${value}) failed with error: `, error);
    }

    return false;
}

const remove = (key) => {

    try {
        validateKey(key);
        
        localStorage.removeItem(key);
        
        return true;
    } catch (error) {
        // console.log(`remove(${key}) failed with error: `, error);
    }

    return false;
}

const removeAll = () => {
    try {
        localStorage.clear();
        return true;
    } catch (error) {
        // console.log("removeAll failed with error: ", error);
    }

    return false;
}

const validateKey = (key) => {

    if (!Object.values(KEYS).includes(key)) {
        throw (`Invalid key - ${key}`);
    }
}

export const LocalStorageActions = {
    retrieve,
    store,
    remove,
    removeAll
}