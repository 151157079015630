import React, { useContext, useEffect } from 'react';
import { KEYS, LocalStorageActions } from './services/LocalData';
import { AuthContext } from './context/AuthContext';
import { CircularProgress } from '@mui/material';

const AuthInit = () => {
    const { authActions } = useContext(AuthContext);
    const user = JSON.parse(LocalStorageActions.retrieve(KEYS.USER_DATA));

    useEffect(() => {
        if (user && user.accessToken) {
            // Store the locastorage content in session
            authActions.restore({
                userId: user.userId,
                mobileNo: user.mobileNo,
                role: user.role,
                accessToken: user.accessToken,
                userEmail: user.userEmail,
                tenantId: user.tenantId,
                planName: user.planName
            })
        }
        else {
            authActions.didTryAutoLogin();
        }
    }, [user])
    return (
        <div>
            <CircularProgress color="secondary" />
        </div>
    );
}

export default AuthInit;