export const initialState = {
    accessToken: null,
    userId: null,
    didTryAutoLogin: false,
    role: null,
    mobileNo: null,
    establishmentId: null
};

const authReducer = (prevstate = initialState, action) => {
    switch (action.actionType) {
        case "LOGIN":
            return {
                ...prevstate,
                accessToken: action.accessToken,
                userId: action.userId,
                role: action.role,
                mobileNo: action.mobileNo,
                didTryAutoLogin: true,
            };
        case "GENERATE_OTP":
            return {
                ...prevstate,
                mobileNo: action.mobileNo,
                userId: action.userId
            };
        case 'DID_TRY_AUTO_LOGIN':
            return {
                ...prevstate,
                didTryAutoLogin: true,
            };
        case "LOGOUT":
            return {
                initialState
            };
        default:
            return prevstate;
    }
};

export default authReducer;