import React, { useEffect, useState, componentDidMount } from "react";
import { Modal } from "react-bootstrap";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  getCartPortfolios,
  removeCart,
  UpdateCart,
} from "../services/portfolioServices";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import CartItem from "./CartItem";
import { Card, Grid, Typography } from "@mui/material";
import QuoteModal from "./QuoteModal";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export default function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [openQuote, setOpenQuote] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  async function removeItem(portfolioId) {
    await removeCart(portfolioId)
      .then((data) => {
        setCartItems(data.data.portfolio);
        handleSnack("success", "Cart item has been removed!");
      })
      .catch((err) => {
        handleSnack(
          "error",
          "Something went wrong while removing the cart item!"
        );
        console.log(err.message);
      });
  }
  async function getCartItems() {
    // setCartItems(mockData);
    await getCartPortfolios()
      .then((data) => {
        console.log(data.data.portfolio);
        setCartItems(data.data.portfolio);
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(cartItems);
  }

  useEffect(() => {
    getCartItems();
    calculateTotalPrice();
  }, []);
  const [netPrice, setNetPrice] = useState(0);
  function calculateTotalPrice() {
    let price = 0;
    cartItems.map((cartItem) => {
      price =
        price +
        cartItem.portfolioPrice.totalPrice * cartItem.portfolioCart.quantity;
    });
    setNetPrice(price);
  }
  const handleClose = () => {
    setOpenQuote(false);
  }
  return (
    <>
      <CustomizedSnackbar
        handleClose={handleSnackBarClose}
        open={openSnack}
        severity={severity}
        message={snackMessage}
      />
      <QuoteModal show={openQuote} handleClose={handleClose} cartItems={cartItems} handleSnack={handleSnack} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid ">
          <div className="my-4">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="card p-4">
                  <h5 className="mb-0">Shopping Cart</h5>
                  {/* <div className="row mt-5">
                    <div className="col-md-5 col-sm-5">
                      <p className="mb-0">ITEM</p>
                    </div>
                    <div className="col-md-7 col-sm-7">
                      <div className="row">
                        <div className="col-md-4 col-sm-4">
                          <p className="mb-0">PRICE</p>
                        </div>
                        <div className="col-md-5 col-sm-5">
                          <p className="mb-0">QUANTITY</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <Grid container>
                    {cartItems &&
                      cartItems.map((item) => (
                        <Grid item xs={12} md={6} sx={{ p: 4 }}>
                          <CartItem
                            key={item.portfolioId}
                            item={item}
                            setCartItems={setCartItems}
                            removeItem={removeItem}
                            handleSnack={handleSnack}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div
                  className="card p-4 border"
                  style={{ border: 1, borderColor: "#000" }}
                >
                  <Grid container xs={12}>
                    <Grid item xs={3} container justifyContent={"center"}>
                      <div>
                        <Typography fontSize={12}>SPARE PARTS</Typography>
                        <Typography fontWeight={500}>{formatter.format(
                          cartItems.reduce((sum, cartItem) => {
                            return (
                              sum +
                              cartItem.portfolioPrice.sparePartsPrice *
                              cartItem.portfolioCart.quantity
                            );
                          }, 0))}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} container justifyContent={"center"}>
                      <div>
                        <Typography fontSize={12}>LABOR CHARGE</Typography>
                        <Typography>{formatter.format(
                          cartItems.reduce((sum, cartItem) => {
                            return (
                              sum +
                              cartItem.portfolioPrice.labourPrice *
                              cartItem.portfolioCart.quantity
                            );
                          }, 0))}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} container justifyContent={"center"}>
                      <div>
                        <Typography fontSize={12}>MISC.</Typography>
                        <Typography>{formatter.format(
                          cartItems.reduce((sum, cartItem) => {
                            return (
                              sum +
                              cartItem.portfolioPrice.miscPrice *
                              cartItem.portfolioCart.quantity
                            );
                          }, 0))}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} container justifyContent={"center"}>
                      <div>
                        <Typography fontSize={12}>DISCOUNT</Typography>
                        <Typography>{formatter.format(
                          cartItems.reduce((sum, cartItem) => {
                            return (
                              sum +
                              cartItem.portfolioPrice.sparePartsPrice *
                              cartItem.portfolioCart.quantity
                            );
                          }, 0))}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={3} container justifyContent={"center"}>
                      <div>
                        <Typography fontSize={12}>TOTAL</Typography>
                        <Typography className="text-primary" fontSize={18} fontWeight={500}>{formatter.format(
                          cartItems.reduce((sum, cartItem) => {
                            return (
                              sum +
                              cartItem.portfolioPrice.totalPrice *
                              cartItem.portfolioCart.quantity
                            );
                          }, 0))}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">

                <a
                  href="/Order"
                  className="btn border-primary text-primary pull-right"
                  style={{ width: '100%' }}
                >
                  CANCEL
                </a>
              </div>
              <div className="col-md-6 col-sm-6">

                <a
                  href={undefined}
                  className="btn bg-primary text-white pull-right"
                  style={{ width: '100%' }}
                  onClick={() => setOpenQuote(true)}
                  cartItems={cartItems}

                >
                  ORDER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

