import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UpdateAccount, getAccount } from "../services/accountService";

export const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "#fff",
    fontSize: 12,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
};

export default function Profile(props) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const handleUpdateAccount = async () => {
    let data = {
      userId: profile.userId,
      fullName: profile.fullName,
      primaryPhoneNumber: profile.primaryPhoneNumber,
      secondaryPhoneNumber: profile.secondaryPhoneNumber,
      customerType: profile.customerType,
      industry: profile.industry,
      fleetSize: profile.fleetSize,
      tenantId: profile.tenantId
    }

    await UpdateAccount(data).then(updatedAccount => {
      fetchAccountDetails();
      props.handleSnack('success', "Profile details successfully updated!")
    }).catch(e => {
      props.handleSnack('error', "Error occurred while updating profile details");
    });
  }

  const fetchAccountDetails = async () => {
    await getAccount()
      .then((accountDetails) => {
        setProfile(accountDetails.data);
        setLoading(false);
      })
      .catch((err) => props.handleSnack('error', "Error occurred while fetching profile details"));
    setLoading(false);
  };
  const [profile, setProfile] = useState();

  const handleCustomerDataChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  return loading ? (
    "Loading..."
  ) : (
    <>
      {props.profileOpen && (
        <Modal
          show={props.profileOpen}
          onHide={props.handleProfileClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="p-0 bg-white">

            <Box sx={{ width: "100%" }}>
              <Grid container spcing={3} sx={{ marginBlock: 7 }}>
                <Grid item container xs={2}>
                  <Avatar
                    alt="Profile Image"
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: 80, height: 80, ml: 4 }}
                  />
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <Box style={{ width: "90%" }}>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      USER NAME
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.fullName}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.fullName}
                        name="fullName"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                        style={{ width: "80%" }}
                        id="customerNameid"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <div style={{ width: "90%" }}>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      EMAIL
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.email ? profile.email : "NA"}
                      </Typography>
                    ) : (
                      <input
                        type="email"
                        value={profile?.email}
                        name="email"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <div style={{ width: "90%" }}>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      PASSWORD
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        *******
                      </Typography>
                    ) : (
                      <input
                        type="password"
                        value={profile?.password}
                        name="password"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                        id="customerNameid"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={1} container alignItems={"center"}>
                  {!editing ? (
                    <Button
                      className="btn bg-primary text-white py-2"
                      variant="contained"
                      onClick={() => setEditing(true)}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      className="btn bg-primary text-white py-2"
                      variant="contained"
                      onClick={handleUpdateAccount}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
              {/* <Divider  sx={{my: 10}}/> */}
              <div
                className="bg-primary px-2 p-1"
                style={{ borderTopLeftRadius: 7, borderTopRightRadius: 7 }}
              >
                <Typography className="text-white p-2">
                  Account Settings
                </Typography>
              </div>
              <Grid container spcing={2} sx={{ marginBlock: 7 }}>
                <Grid item xs={3} container alignItems={"center"}>
                  <Box sx={{ ml: 4 }}>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      ACCOUNT NO
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.accountNumber ? profile.accountNumber : "NA"}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.accountNumber}
                        name="accountNumber"
                        style={{ width: "80%" }}
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <div>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      ACCOUNT NAME
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.accountName ? profile.accountName : "NA"}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.accountName}
                        name="accountName"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <div>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      CONTACT NO
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.primaryPhoneNumber}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.primaryPhoneNumber}
                        name="primaryPhoneNumber"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={3} container alignItems={"center"}>
                  <div>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      ALTERNATE CONTACT
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.secondaryPhoneNumber}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.secondaryPhoneNumber}
                        name="secondaryPhoneNumber"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <div
                className="bg-primary px-2 p-1"
                style={{ borderTopLeftRadius: 7, borderTopRightRadius: 7 }}
              >
                <Typography className="text-white p-2">
                  Account Details
                </Typography>
              </div>
              <Grid container spcing={2} sx={{ marginBlock: 7 }}>
                <Grid item xs={4} container alignItems={"center"}>
                  <Box sx={{ ml: 4 }}>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      CUSTOMER TYPE
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.customerType}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.customerType}
                        name="customerType"
                        style={{ width: "80%" }}
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4} container alignItems={"center"}>
                  <div>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      INDUSTRY
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.industry}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.industry}
                        name="industry"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={4} container alignItems={"center"}>
                  <div>
                    <Typography sx={{ color: "gray", fontSize: 13 }}>
                      FLEET SIZE
                    </Typography>
                    {!editing ? (
                      <Typography className="text-light" variant="h6">
                        {profile?.fleetSize}
                      </Typography>
                    ) : (
                      <input
                        type="text"
                        value={profile?.fleetSize}
                        name="fleetSize"
                        onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-input"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>

          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
