import React from "react";
import { Modal } from "react-bootstrap";

const ViewOrderProfile = ({ show, hideModal }) => {
  return (
    <Modal size="md" show={show} onHide={hideModal} centered>
      <Modal.Header>
        <Modal.Title>2012 BMW 328i L6-3.0L</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Change Oil & Filter</h6>
        <h6 className="mt-3">Check Engine Light is on (Inspection)</h6>
        <h6 className="mt-3">Custom Request</h6>
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <p className="mb-0">Labor</p>
          <p className="mb-0">$191.73</p>
        </div>
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <p className="mb-0">Parts</p>
          <p className="mb-0">$0.00</p>
        </div>
        <div className="mt-2 border-modal-order pb-2 d-flex align-items-center justify-content-between">
          <p className="mb-0">Tax</p>
          <p className="mb-0">$0.00</p>
        </div>
        <div className="mt-2 pb-2 d-flex align-items-center justify-content-between">
          <p className="mb-0 text-black font-size-700">Your total price</p>
          <p className="mb-0 text-green font-size-700">
            <span className="mr-3 text-black">
              <s>$389.00</s>
            </span>
            $191.73
          </p>
        </div>
        <p className="text-right text-green">
          You save 51% compared to dealer price.
        </p>
        <h6 className="mt-3">Save your quote</h6>
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-group mb-0">
            <input
              type="email"
              className="form-control border-radius-10"
              placeholder="Email Id"
              name="emailId"
            />
          </div>
          <a className="btn bg-primary text-white">Save My Quote</a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderProfile;