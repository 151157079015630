import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import { BASE_URL } from "./CONSTANTS";
import Cookies from "js-cookie";

export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  console.log("storedcookie", JSON.parse(storedCookie)?.access_token);
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

//Update Account
export const UpdateAccount = (data) => {
  console.log("service Commerce > Update account called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(`${BASE_URL}commerce-svc/v1/user/account`, data, getAccessToken())
        .then((res) => {
          console.log("AccountService -> Update account response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("update account > axios err=", err);
          reject("Error in update account axios!");
        });
    } catch (error) {
      console.error("CreateBuilder general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//get Account
export const getAccount = () => {
  console.log("service Commerce > Update account called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${BASE_URL}commerce-svc/v1/user/account`, getAccessToken())
        .then((res) => {
          console.log("AccountService -> Update account response: ", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          console.log("get account > axios err=", err);
          reject("Error in update account axios!");
        });
    } catch (error) {
      console.error("get account general exception", error);
      reject(SYSTEM_ERROR);
    }
  });
};