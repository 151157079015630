import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Modal } from "react-bootstrap";

const EquipmentQuoteAddEdit = ({
  show,
  hideModal,
  modalType,
  edit = false,
}) => {
  const EquipmentDetails = () => {
    return (
      <>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Manufacturer
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Manufacturer"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Model
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Model"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Date of Purchase
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Date"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Address
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Address"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Contracts/EW
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Contracts"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Manufacturing Year
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder=" Serial Number"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Service Program
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder=" Serial Number"
            />
          </div>
        </div>
      </>
    );
  };
  const UsageDetails = () => {
    return (
      <>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Current Usage
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Manufacturer"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Current Usage
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder=" Current Usage"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Average Usage
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Average Usage"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Last Updated Date
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Last Updated Date"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              Sensor ID
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="Sensor ID"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              SMU ID
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="SMU ID"
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="form-group">
            <label
              className="text-light-dark font-size-12 font-weight-500"
              htmlFor="exampleInputEmail1"
            >
              SMU Type
            </label>
            <input
              className="form-control border-light-blue text-primary border-radius-10"
              placeholder="SMU Type"
            />
          </div>
        </div>
      </>
    );
  };
  const UsageTableDetails = () => {
    return (
      <>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Current Usage
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Average Usage
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              336037
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Last Updated Date
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Sensor ID
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              SMU ID
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              SMU Type
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
      </>
    );
  };
  const EquipmentTableDetails = () => {
    return (
      <>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Manufacturer
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Model
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              336037
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Date Of Purchase
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Address
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Contracts/EW
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Manufacturing Year
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
          <div className="d-block">
            <p className="text-light-60 font-size-12 m-0 font-weight-500">
              Service Program
            </p>
            <p className="text-primary font-size-12 mt-1 font-weight-500">
              Caterpillar
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <Modal size="lg" show={show} onHide={hideModal} centered>
      <Modal.Header>
        <Modal.Title>
          {modalType === "equipmentProfile" && "New Machine Details"}
          {modalType === "usageProfile" && "Usage Details"}
          {modalType === "equipmentTableModal" && "Machine Details"}
          {modalType === "usageTableModal" && "Usage Details"}
          {edit && (
            <span className="ml-2">
              <EditOutlinedIcon className="text-primary" />
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row input-fields">
          {modalType === "equipmentProfile" && EquipmentDetails()}
          {modalType === "usageProfile" && UsageDetails()}
          {modalType === "equipmentTableModal" && EquipmentTableDetails()}
          {modalType === "usageTableModal" && UsageTableDetails()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a className="btn bg-primary text-white" onClick={hideModal}>
          Close
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default EquipmentQuoteAddEdit;
