/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 */
export const BASE_URL = process.env.REACT_APP_API_BASEURL;
const USER_SERVICE_URI = BASE_URL + "user-svc/v1/";
const DATA_SERVICE_URI = BASE_URL + "data-svc/api/v1/";

export const USER_SERVICE_FORGOT_PASSWORD = () => USER_SERVICE_URI + "user/forgot-password";
export const USER_SERVICE_RESET_PASSWORD = () => USER_SERVICE_URI + "user/new-password";
export const DATA_SVC_EQUIPMENT = () => DATA_SERVICE_URI + "equipment";