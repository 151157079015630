import EastIcon from "@mui/icons-material/East";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import CabinIcon from "@mui/icons-material/Cabin";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { addToCart } from "../services/portfolioServices";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PortfolioSummary from "../pages/PortfolioSummary";
import { useState } from "react";
import moment from "moment/moment";

const COLOR_PALLETTE = { STANDARD: "#7bcaa3", CUSTOMIZED: "#0a0a0a", PREMIUM: "#c49ff4", SUPERIOR: "#fafc9f" }
export default function CarouselTile({ product }) {
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const validity = product.unit === "EMPTY" ? moment.duration(moment(product.validTo).diff(moment(product.validFrom))) : (product.endUsage - product.startUsage)
  async function addItem(id) {
    let config = {
      quantity: 1,
      optionalServices: '',
      partsRequired: true,
      serviceRequired: false,
      labourRequired: true,
      miscRequired: true,
    }
    // debugger;
    await addToCart(id, '', config)
      .then((response) => {
        history("/cart");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <>
      <div className="item3 border" style={{ height: '300px', backgroundColor: COLOR_PALLETTE[product.supportLevel] }}>
        {/* <div className="card-bg p-4">
          {product.orderedBefore && (
            <a href="#" className="btn text-white custom-green-btn">
              Ordered Before
              <EastIcon className="ml-3 font-size-16" />
            </a>
          )}
        </div>
        <div className="d-flex px-3 py-2" style={{ background: "#D0E1EF" }}>
          <div>
            <LightbulbOutlinedIcon className="text-light" />
          </div>
          <div>
            <p className="text-light ml-3">{product.suggestion}</p>
          </div>
        </div> */}
        <div className="p-3">
          {/* <a href="#" className="mt-3 bg-primary text-white btn">
            <CabinIcon className=" font-size-16 mr-2"></CabinIcon>
            {product.portfolioId ? "Portfolio Solution" : "Solution Template"}
          </a> */}

          <h6 className="text-light mt-1" style={{ fontWeight: 600 }}>
            {/* {product.portfolioId
              ? "PS" + product.portfolioId
              : "SC" + product.id} */}
            {product.supportLevel}
          </h6>
          <h5 className="my-4">
            {product.name}
          </h5>
          <p className="font-size-12 my-2">
            {product.description}
          </p>
          <hr />
          <p className="font-size-12">Validity: {product.unit === "EMPTY" ?
            ((validity._data.years ? validity._data.years + " YEARS " : "") + (validity._data.months ? validity._data.months + " MONTHS " : ""))
            : (validity) + " " + (product.unit)}</p>
          <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
            <h2 className="mb-0">
              {formatter.format(product.portfolioPrice?.totalPrice)}
            </h2>
            <div className="text-right">
              <button className="service-button" onClick={() => setShow(true)}>
                <ArrowForwardIcon className="font-size-30" size="large"></ArrowForwardIcon>
              </button>
            </div>
          </Stack>
          <PortfolioSummary show={show} portfolio={product} addItem={addItem} handleClose={() => setShow(false)} />
          {/* <p className="font-size-12">
            This{" "}
            {product.portfolioId ? "Portfolio Solution" : "Solution Template"}{" "}
            has been developed for{" "}
            {product.coverages?.map((indCov) => {
              return <span>{indCov.modelNo}</span>;
            })}{" "}
            with {product.strategyTask}{" "}
          </p> 
          <p className="font-size-12">
            The solution is valid for machines with usage hours between{" "}
            {new Intl.DateTimeFormat('en',{dateStyle: 'short', timeStyle: 'short'}).format(new Date(product.validFrom))} and {product.validTo} or machines that are{" "}
            {product.lifeStageOfMachine} old.
          </p>

          <h5 className=" mt-4">INCLUDES</h5>
          <ul>
            <li className="font-size-12 my-2">
              <span className="mr-3 ">
                <FormatListBulletedOutlinedIcon />
              </span>
              <span>The portfolio covers {product.numberOfEvents}</span>
            </li>
            {product.items?.map((item) => (
              <>
                <li className="font-size-12 my-2">
                  <span className="mr-3 ">
                    <FormatListBulletedOutlinedIcon />
                  </span>
                  {item.name} with periodic maintenance{" "}
                  {item.itemBodyEntity?.recommendedValue}{" "}
                  {item.itemBodyEntity?.unit}
                </li>
              </>
            ))}
          </ul>
          <div>
            <a href="#" style={{ textDecoration: "underline" }}>
              View Details
            </a>
          </div>
          <div className="Choose-btn2 bg-primary px-2">
            <h6 className="mb-0 text-white">
              {formatter.format(product.portfolioPrice?.totalPrice)}
            </h6>
            <Button
              onClick={() => addItem(product.portfolioId)}
              className="btn bg-primary text-white "
            >
              Add to Cart
              <EastIcon className="ml-3 font-size-16" />
            </Button>
          </div>*/}
        </div>
      </div >
    </>
  );
}
