import "./GuidedSolutionModal.css";
import React, { useEffect, useState, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import {
  addToCart,
  fetchCoverageSuggestions,
  getPortfolioSearch,
} from "../services/portfolioServices";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Divider,
  FormGroup,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  debounce,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ForwardIcon from '@mui/icons-material/Forward';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { USAGE_TYPES } from "./CONSTANTS";
import CustomizedSnackbar from "../shared/CustomSnackBar";
import { useNavigate } from "react-router-dom";

const PARTS_ONLY = "partsOnly";
const SERVICE_ONLY = "serviceOnly";
const WITH_PARTS = "withParts";

export default function GuidedSolutionModal(props) {
  const selectedCategory = props.searchSolutionSelected;
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [make, setMake] = React.useState("");
  const [model, setModel] = useState("");
  const [family, setFamily] = React.useState("");
  const [prefix, setPrefix] = useState("");
  const [dataOption, setDataOption] = React.useState("all");
  const [selectedOpServices, setSelectedOpServices] = useState([]);
  const [selectedMisc, setSelectedMisc] = useState([]);
  const [result, setResult] = useState([]);
  const [tabValue, setTabValue] = useState(selectedCategory);
  const changeTab = (event, type) => {
    onSearchButtonClick(type);
    // setTabValue(type);
  };
  const [choiceOfParts, setChoiceOfParts] = useState(WITH_PARTS);
  const [labourRequired, setLabourRequired] = useState(true);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (selectedCategory) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            "filterKey": "usageCategory",
            "value": selectedCategory,
            "operation": "eq"
          }
        ],
        dataOption: "all",
        listItem: "make"
      }
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        setMakeSuggestions(results.data.map(indMake => indMake.value));
      }).catch(err => console.log(err))
    }
  }, [selectedCategory])
  const history = useNavigate();
  const [makeSuggestions, setMakeSuggestions] = useState([]);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [familySuggestions, setFamilySuggestions] = useState([]);
  const [prefixSuggestions, setPrefixSuggestions] = useState([]);
  const addPortfolioToCart = async () => {
    let selectedCoverageId = selectedPortfolio.coverages?.length > 0 ? "?coverageId=" + selectedPortfolio?.coverages[0]?.coverageId : '';
    let config = {
      quantity: 1,
      optionalServices: selectedOpServices.toString(),
      partsRequired: (choiceOfParts === WITH_PARTS || choiceOfParts === PARTS_ONLY) ? true : false,
      serviceRequired: (choiceOfParts === WITH_PARTS || choiceOfParts === SERVICE_ONLY) ? true : false,
      labourRequired: labourRequired,
      miscRequired: selectedMisc?.length > 0 ? true : false,
    }
    await addToCart(selectedPortfolio.portfolioId, selectedCoverageId, config)
      .then((response) => {
        handleSnack('success', "Successfully added to cart")
        history("/cart");
      })
      .catch((err) => {
        console.log(err);
        handleSnack('error', err.message);
      });
  }
  async function onSearchButtonClick(category) {
    // console.log(2, make, family, model, prefix)
    if (!(model && make && prefix && family)) {
      handleSnack('error', "Please fill all the categories")
    } else {
      let body = {};
      body.searchCriteriaList = [
        {
          filterKey: "usageCategory",
          value: category ? category : tabValue,
          operation: "eq",
        },
        {
          filterKey: "make",
          value: make,
          operation: "eq",
        },
        {
          filterKey: "modelNo",
          value: model,
          operation: "eq",
        },
        {
          filterKey: "family",
          value: family,
          operation: "eq",
        },
        {
          filterKey: "serialNumberPrefix",
          value: prefix,
          operation: "eq",
        },
      ];

      body.dataOption = dataOption;

      await getPortfolioSearch(body)
        .then((data) => {
          if (data.data?.length === 0) {
            handleSnack("info", "No matching result found!")
          } else {
            setResult(data.data);
            setTabValue(category);
          }
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching for portfolios!")
        });
    }

    // window.open("/results", "_blank");
  }
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...selectedOpServices];
    if (event.target.checked) {
      updatedList = [...selectedOpServices, event.target.value];
    } else {
      updatedList.splice(selectedOpServices.indexOf(event.target.value), 1);
    }
    setSelectedOpServices(updatedList);
  };

  const handleMisc = (event) => {
    var updatedList = [...selectedMisc];
    if (event.target.checked) {
      updatedList = [...selectedMisc, event.target.value];
    } else {
      updatedList.splice(selectedMisc.indexOf(event.target.value), 1);
    }
    setSelectedMisc(updatedList);
  };

  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2);
  };

  const PriceComponent = ({ labourRequired, choiceOfParts, miscRequired }) => (
    <Card sx={{ borderRadius: 4 }}>
      <Box
        sx={{
          backgroundColor: "#872ff7",
          color: "#ffffff",
          width: "100%",

          p: 2,
          py: 6,
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <span>Total Price</span>
            <Typography sx={{ fontSize: 25 }}>
              {console.log(!labourRequired && choiceOfParts !== SERVICE_ONLY ?
                (formatPrice(selectedPortfolio.portfolioPrice?.totalPrice - selectedPortfolio.portfolioPrice?.labourPrice)) :
                (!labourRequired && choiceOfParts === SERVICE_ONLY ? (formatPrice(selectedPortfolio.portfolioPrice?.totalPrice - (selectedPortfolio.portfolioPrice?.labourPrice + selectedPortfolio.portfolioPrice?.sparePartsPrice))) :
                  formatPrice(selectedPortfolio.portfolioPrice?.totalPrice)), selectedPortfolio.portfolioPrice?.labourPrice + selectedPortfolio.portfolioPrice?.sparePartsPrice)}
              ${!labourRequired && choiceOfParts !== SERVICE_ONLY ?
                (formatPrice(selectedPortfolio.portfolioPrice?.totalPrice - selectedPortfolio.portfolioPrice?.labourPrice)) :
                (!labourRequired && choiceOfParts === SERVICE_ONLY ? (formatPrice(selectedPortfolio.portfolioPrice?.totalPrice - (selectedPortfolio.portfolioPrice?.labourPrice + selectedPortfolio.portfolioPrice?.sparePartsPrice))) :
                  formatPrice(selectedPortfolio.portfolioPrice?.totalPrice))}
            </Typography>
          </Grid>
          <Grid item container xs={7}>
            <Grid item xs={9}>
              <Typography fontSize={12}>Spare Parts Price </Typography>
            </Grid>
            <Grid item xs={3}>
              <strong>
                $
                {formatPrice(selectedPortfolio.portfolioPrice?.sparePartsPrice)}
              </strong>
            </Grid>
            <Grid item xs={9}>
              <Typography fontSize={12}>Labour Price </Typography>
            </Grid>
            <Grid item xs={3}>
              <strong>
                ${formatPrice(selectedPortfolio.portfolioPrice?.labourPrice)}
              </strong>
            </Grid>
            <Grid item xs={9}>
              <Typography fontSize={12}>Misc Price</Typography>
            </Grid>
            <Grid item xs={3}>
              {" "}
              <strong>
                ${formatPrice(selectedPortfolio.portfolioPrice?.miscPrice)}
              </strong>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
  const handleChangeMake = (value) => {
    console.log(value, selectedCategory);
    setMake(value);
    if (value && selectedCategory) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            "filterKey": "usageCategory",
            "value": selectedCategory,
            "operation": "eq"
          },
          {
            "filterKey": "make",
            "value": value,
            "operation": "eq"
          }
        ],
        dataOption: "all",
        listItem: "family"
      }
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        setFamilySuggestions(results.data.map(indFamily => indFamily.value));
      }).catch(err => console.log(err))
    }
    setFamily("");
    setModel("");
    setPrefix("")
  }
  const handleChangeFamily = (value) => {
    console.log(value, selectedCategory);
    setFamily(value);
    if (selectedCategory && make && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            "filterKey": "usageCategory",
            "value": selectedCategory,
            "operation": "eq"
          },
          {
            "filterKey": "make",
            "value": make,
            "operation": "eq"
          },
          {
            "filterKey": "family",
            "value": value,
            "operation": "eq"
          }
        ],
        dataOption: "all",
        listItem: "modelNo"
      }
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        setModelSuggestions(results.data.map(indModel => indModel.value));
      }).catch(err => console.log(err))
    }
    setModel("");
    setPrefix("")
  }
  const handleChangeModel = (value) => {
    console.log(value, selectedCategory);
    setModel(value);
    if (selectedCategory && make && family && value) {
      let searchCriteria = {
        searchCriteriaList: [
          {
            "filterKey": "usageCategory",
            "value": selectedCategory,
            "operation": "eq"
          },
          {
            "filterKey": "make",
            "value": make,
            "operation": "eq"
          },
          {
            "filterKey": "family",
            "value": family,
            "operation": "eq"
          },
          {
            "filterKey": "modelNo",
            "value": value,
            "operation": "eq"
          }
        ],
        dataOption: "all",
        listItem: "serialNumberPrefix"
      }
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        setPrefixSuggestions(results.data.map(indPrefix => indPrefix.value));
      }).catch(err => console.log(err))
    }
    setPrefix("")
  }

  const resetSearch = () => {
    // setMakeSuggestions([])
    setModelSuggestions([]);
    setFamilySuggestions([])
    setPrefixSuggestions([]);
    setMake("");
    setModel("")
    setFamily("")
    setPrefix("");
  }
  const findSuggestions = async (searchCategory, searchText) => {
    let searchCriteriaList = []
    if (searchCategory === "make") {
      searchCriteriaList = [
        {
          filterKey: searchCategory,
          value: searchText,
          operation: "cn",
        },
      ];
    } else if (searchCategory === "family") {
      searchCriteriaList = [
        {
          filterKey: "make",
          value: make,
          operation: "eq",
        },
        {
          filterKey: searchCategory,
          value: searchText,
          operation: "cn",
        },
      ];
    } else if (searchCategory === "modelNo") {
      searchCriteriaList = [
        {
          filterKey: "make",
          value: make,
          operation: "eq",
        },
        {
          filterKey: "family",
          value: family,
          operation: "eq",
        },
        {
          filterKey: searchCategory,
          value: searchText,
          operation: "cn",
        },
      ];
    } else if (searchCategory === "serialNumberPrefix") {
      searchCriteriaList = [
        {
          filterKey: "make",
          value: make,
          operation: "eq",
        },
        {
          filterKey: "family",
          value: family,
          operation: "eq",
        },
        {
          filterKey: "modelNo",
          value: model,
          operation: "eq",
        },
        {
          filterKey: searchCategory,
          value: searchText,
          operation: "cn",
        },
      ];
    }



    let searchCriteria = {
      searchCriteriaList: searchCriteriaList,
      dataOption: dataOption,
      listItem: searchCategory
    };
    if (searchText && searchCategory) {
      fetchCoverageSuggestions(searchCriteria).then((results) => {
        if (searchCategory === "make") setMakeSuggestions(results.data.map(indMake => indMake.value));
        else if (searchCategory === "modelNo")
          setModelSuggestions(results.data.map(indModel => indModel.value));
        else if (searchCategory === "family")
          setFamilySuggestions(results.data.map(indFamily => indFamily.value));
        else if (searchCategory === "serialNumberPrefix")
          setPrefixSuggestions(results.data.map(indPrefix => indPrefix.value));
      });
    } else {
      setMakeSuggestions([]);
      setModelSuggestions([]);
    }
  };

  const debounceLoadData = useCallback(
    debounce(
      (searchCategory, searchText) =>
        findSuggestions(searchCategory, searchText),
      500
    ),
    []
  );

  function handleFilterChange(event, newValue) {
    if (event)
      findSuggestions(event.target.name, newValue);
  }

  const handleChangeChoiceParts = (e) => {
    if (!e.target.checked) {
      handleSnack("warning", "Please select one of the options from choice of spare parts")
    } else {
      setChoiceOfParts(e.target.name);
      if (e.target.name === PARTS_ONLY)
        setLabourRequired(false);
      else
        setLabourRequired(true);
    }
  }
  return (
    <Modal
      show={props.searchPortfolioOpen}
      // style={{height: '100%'}}
      onHide={() => {
        setResult([]);
        resetSearch();
        setSelectedPortfolio("");
        props.handleSearchPortfolioClose();
      }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0 bg-white">
        <CustomizedSnackbar
          handleClose={handleSnackBarClose}
          open={openSnack}
          severity={severity}
          message={snackMessage}
        />
        {/* <Typography>{selectedCategory}</Typography> */}
        {!(result && result.length > 0) ? (
          <>
            <div className="modal-header ">
              <h4 className="modal-title" id="myModalLabel2">
                Search Portfolio Template
              </h4>
              <a href="#" className="btn bg-primary text-white py-2">
                Guided Solution
              </a>
            </div>
            <div className="card border mx-3 my-4">
              <div className="modal-footer-guided">
                {/* From Portfolio Summary */}
                <div className="search-box py-1 px-1" style={{ border: '1.75px solid #872ff7' }}>
                  <SearchLabel>
                    Make
                  </SearchLabel>
                  <div className="customselectsearch">
                    <Autocomplete
                      options={makeSuggestions}
                      // loading={loading}
                      onInputChange={(event, newInputValue) => {
                        handleFilterChange(event, newInputValue);
                      }}
                      placeholder="Search Make"
                      value={make}
                      noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
                      renderOption={(props, option) => (
                        <li {...props} style={{ ...props.style, fontSize: 12, borderBottom: '1px solid #00000025' }}>{option}</li>
                      )}
                      filterOptions={(x) => x}
                      onChange={(event, value) => { handleChangeMake(value) }}
                      // getOptionLabel={(option) => option.value ? option.value : ""}
                      size="small"
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            placeholder="Search ..."
                            {...params.inputProps}
                            style={{ border: 0, width: 100 }}
                            name="make"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <ForwardIcon fontSize="large" sx={{ color: make ? '#872ff7' : "" }} />
                <div className="search-box py-1 px-1" style={{ border: make ? '1.75px solid #872ff7' : '1.75px solid #76838f' }}>
                  <SearchLabel>
                    Family
                  </SearchLabel>
                  <div className="customselectsearch">
                    <Autocomplete
                      options={familySuggestions}
                      // loading={loading}
                      onInputChange={(event, newInputValue) =>
                        handleFilterChange(event, newInputValue)
                      }
                      value={family}
                      placeholder="Search Family"
                      onChange={(event, value) => handleChangeFamily(value)}
                      // getOptionLabel={(option) => option ? option : ""}
                      noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
                      size="small"
                      renderOption={(props, option) => (
                        <li {...props} style={{ ...props.style, fontSize: 12, borderBottom: '1px solid #00000025' }}>{option}</li>
                      )}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            placeholder="Search ..."
                            {...params.inputProps}
                            style={{ border: 0, width: 100 }}
                            name="family"
                            disabled={!make}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <ForwardIcon fontSize="large" sx={{ color: make && family ? '#872ff7' : "" }} />
                <div className="search-box py-1 px-1" style={{ border: make && family ? '1.75px solid #872ff7' : '1.75px solid #76838f' }}>
                  <SearchLabel>
                    Model
                  </SearchLabel>
                  <div className="customselectsearch">
                    <Autocomplete
                      options={modelSuggestions}
                      // loading={loading}
                      onInputChange={(event, newInputValue) => {
                        handleFilterChange(event, newInputValue);
                      }}
                      value={model}
                      onChange={(event, values) => handleChangeModel(values)}
                      noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
                      size="small"
                      renderOption={(props, option) => (
                        <li {...props} style={{ ...props.style, fontSize: 12, borderBottom: '1px solid #00000025' }}>{option}</li>
                      )}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            placeholder="Search ..."
                            {...params.inputProps}
                            style={{ border: 0, width: 100 }}
                            disabled={!(make && family)}
                            name="modelNo"
                          />
                        </div>
                      )}

                    />
                  </div>
                </div>
                <ForwardIcon fontSize="large" sx={{ color: make && family && model ? '#872ff7' : "" }} />
                <div className="search-box py-1 pl-1" style={{ border: make && family && model ? '1.75px solid #872ff7' : '1.75px solid #76838f' }}>
                  <SearchLabel> Prefix</SearchLabel>
                  <div className="customselectsearch">
                    <Autocomplete
                      options={prefixSuggestions}
                      // loading={loading}
                      onInputChange={(event, newInputValue) => {
                        handleFilterChange(event, newInputValue);
                      }}
                      value={prefix}
                      onChange={(event, values) => setPrefix(values)}
                      // getOptionLabel={(option) => option.value && option.value}
                      noOptionsText={<span style={{ fontSize: 12 }}>No Options</span>}
                      size="small"
                      renderOption={(props, option) => (
                        <li {...props} style={{ ...props.style, fontSize: 12, borderBottom: '1px solid #00000025' }}>{option}</li>
                      )}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            placeholder="Search..."
                            {...params.inputProps}
                            style={{ border: 0, width: 100 }}
                            name="serialNumberPrefix"
                            disabled={!(make && family && model)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="second-line">
              <Button
                variant="contained"
                className="search-button"
                onClick={() => resetSearch()}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                className="search-button"
                onClick={() => onSearchButtonClick(selectedCategory)}
              >
                Search
              </Button>
            </div>
          </>
        ) : !(selectedPortfolio && selectedPortfolio.portfolioId) ? (

          <div>
            <Grid container sx={{ marginTop: 2 }}>
              <Grid item container xs={12} md={10}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: 17,
                    color: "#4f505b",
                    pl: 3,
                  }}
                >
                  Select Your Services
                </Typography>
              </Grid>
              <Grid item container xs justifyContent={"end"}>
                <Button
                  variant="contained"
                  className="search-button text-right"
                  onClick={() => setResult([])}
                  sx={{ marginRight: 4 }}
                >
                  <ArrowBackIcon fontSize="medium" sx={{ mr: 1 }} />
                  Search
                </Button>
              </Grid>
            </Grid>
            <Box
              alignItems="center"
              sx={{
                mx: 3,
                border: 1,
                borderRadius: 3,
                borderColor: "divider",
                marginTop: 3,
              }}
            >
              <Tabs value={tabValue} onChange={changeTab} centered>
                {USAGE_TYPES.map((usageCat, index) => (
                  <Tab
                    label={usageCat.label}
                    sx={{
                      px: index === 0 ? 0 : 5,
                      borderRight: index === USAGE_TYPES.length - 1 ? 0 : 1,
                      width: "25%",
                    }}
                    value={usageCat.value}
                  />
                ))}
              </Tabs>
            </Box>
            <Box
              sx={{
                m: 3,
                border: 1,
                borderRadius: 3,
                borderColor: "lightgrey",
                // height: 400
              }}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 17, color: "grey", mb: 2, paddingLeft: 1 }}
                  >
                    Recommended Services
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ borderLeft: 1, borderColor: "lightgrey" }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 17, color: "grey", mb: 2, paddingLeft: 1 }}
                  >
                    Search Results
                  </Typography>
                  <Divider />
                  {result.map((indPortfolio) => (
                    <Grid container sx={{ padding: 1 }}>
                      <Grid item container xs={9}>
                        <AddCircleOutlineIcon
                          sx={{
                            fontSize: 40,
                            color: "#87b8ef",
                            marginRight: 2,
                          }}
                        />
                        <Typography sx={{ fontSize: 16 }}>
                          {indPortfolio.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <a
                          href={undefined}
                          onClick={() => setSelectedPortfolio(indPortfolio)}
                        >
                          <Typography
                            mx={2}
                            sx={{
                              fontSize: 14,
                              ":hover": { color: "blue", cursor: "pointer" },
                            }}
                          >
                            DETAILS
                          </Typography>
                        </a>
                      </Grid>
                      <Divider />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </div>
        ) : (
          <Box>
            <Grid container sx={{ marginTop: 2 }}>
              <Grid item container xs={12} md={10}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: 17,
                    color: "#4f505b",
                    pl: 3,
                  }}
                >
                  {selectedPortfolio.name}
                </Typography>
              </Grid>
              <Grid item container xs>
                <Button
                  variant="contained"
                  className="search-button text-right"
                  onClick={() => setSelectedPortfolio("")}
                // sx={{ marginRight: 3, marginTop: 2 }}
                >
                  <ArrowBackIcon fontSize="medium" sx={{ mr: 1 }} />
                  Search Results
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />

            <Grid container>
              <Grid item xs={12} md={8} sx={{ p: 2 }}>
                <Card
                  sx={{ px: 1, pt: 1, color: "grey", height: 600 }}
                  variant="outlined"
                >
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#ebf8ff",
                      border: 2,
                      borderColor: "#86b8ef",
                      py: 2,
                      px: 1,
                      color: "#1d7ae2",
                    }}
                  >
                    <WorkspacePremiumIcon fontSize="large" /> Your Service is
                    backed by our 12 months, 12,000 miles warranty.{" "}
                    <strong>Learn More</strong>
                  </Card>
                  {/* <Typography sx={{ fontSize: 14, color: "#4f505b" }}>                 
                  <strong style={{ marginRight: 20 }}>
                    Included for free with this service:{" "}
                  </strong>
                  {selectedPortfolio.freeServices}
                </Typography> */}
                  <Card sx={{ p: 4, m: 1 }} elevation={4}>
                    <Typography
                      sx={{ fontWeight: "500", fontSize: 17, color: "#4f505b" }}
                    >
                      {selectedPortfolio.description}
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      p: 4,
                      m: 1,
                      overflow: "hidden",
                      overflowY: "scroll",
                      height: 350,
                    }}
                    elevation={4}
                  >
                    <Typography
                      sx={{ fontWeight: "500", fontSize: 17, color: "#4f505b" }}
                    >
                      Select Optional Service
                    </Typography>

                    {/* <span>Checked: {selectedOpServices.map((service, index) => service + (index < selectedOpServices.length -1 ? ",": ""))}</span> */}
                    <FormControl
                      sx={{ my: 2, mx: 1, width: "100%" }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup>
                        <Grid container>
                          {selectedPortfolio.optionalServices
                            ?.split(",")
                            .map((optionalService) => (
                              <Grid item xs={12} sm={6}>
                                <Card
                                  variant="outlined"
                                  sx={{ py: 1, px: 2, m: 2 }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={handleCheck}
                                        name={optionalService}
                                        value={optionalService}
                                      />
                                    }
                                    label={optionalService}
                                  />
                                </Card>
                                <Typography sx={{ fontSize: 12, px: 2 }}>
                                  The objective description focuses on an
                                  impersonal story form and uses a general point
                                  of view. On the other hand, the subjective
                                  description shows a personal point of view,
                                  that is, the author�s thoughts and feelings
                                  are involved.
                                </Typography>
                              </Grid>
                            ))}
                          {!selectedPortfolio.optionalServices && (
                            <Grid item xs={12} md={6}>
                              <Typography
                                variant="h6"
                                sx={{ py: 1, my: 2, color: "#8d9497" }}
                              >
                                No Optional Services Found
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Card>
                </Card>
              </Grid>

              <Grid item container xs={12} md={4} sx={{ p: 2 }}>
                <Card sx={{ px: 1, pt: 1, color: "grey" }} variant="outlined">
                  <Grid item xs={12}>
                    <PriceComponent labourRequired={labourRequired} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: 400,
                      overflow: "hidden",
                      overflowY: "scroll",
                      p: 2,
                    }}
                  >
                    <Typography fontSize={12}>Customer Name</Typography>
                    <Typography fontSize={12}>
                      {make} &nbsp; {family} &nbsp; {model} &nbsp; {prefix}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontSize={17}
                      className="config-labels"
                      marginY={2}
                    >
                      CHOICE OF SPAREPARTS
                    </Typography>
                    <FormGroup sx={{ fontSize: 12 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            defaultChecked
                            name={WITH_PARTS}
                            checked={WITH_PARTS === choiceOfParts}
                            onChange={(e) => handleChangeChoiceParts(e)}
                          />}
                        label={
                          <Typography fontSize={14}>
                            With Spare Parts
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name={SERVICE_ONLY}
                            checked={SERVICE_ONLY === choiceOfParts}
                            onChange={(e) => handleChangeChoiceParts(e)}
                          />}
                        label={
                          <Typography fontSize={14}>
                            Without Spare Parts
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            name={PARTS_ONLY}
                            checked={PARTS_ONLY === choiceOfParts}
                            onChange={(e) => handleChangeChoiceParts(e)}
                          />}
                        label={
                          <Typography fontSize={14}>
                            Only Spare Parts
                          </Typography>
                        }
                      />
                    </FormGroup>
                    <Typography
                      variant="h6"
                      fontSize={17}
                      className="config-labels"
                      marginY={2}
                    >
                      CHOICE OF LABOR
                    </Typography>
                    <FormGroup sx={{ fontSize: 12 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            defaultChecked
                            checked={labourRequired}
                            disabled={choiceOfParts === 'partsOnly'}
                            onChange={(e) => setLabourRequired(e.target.checked)}
                          />}
                        label={
                          <Typography fontSize={14}>I need labor</Typography>
                        }
                      />
                      {/* <FormControlLabel
                        control={<Switch size="small" />}
                        label={
                          <Typography fontSize={14}>I don't need labor</Typography>
                        }
                      /> */}
                    </FormGroup>
                    <Typography
                      variant="h6"
                      fontSize={17}
                      className="config-labels"
                      marginY={2}
                    >
                      CHOICE OF MISC.
                    </Typography>
                    <FormGroup sx={{ fontSize: 12 }}>
                      <FormControlLabel
                        control={<Switch size="small" name={"consumable"} value="consumable" onChange={handleMisc} />}
                        label={
                          <Typography fontSize={14}>Require consumables</Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Switch size="small" name={"tools"} value="tools" onChange={handleMisc} />}
                        label={
                          <Typography fontSize={14}>Require assistance with tools</Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Switch size="small" name={"movement"} value="movement" onChange={handleMisc} />}
                        label={<Typography fontSize={14}>Require assistance with movement</Typography>}
                      />
                      <FormControlLabel
                        control={<Switch size="small" name={"extWork"} value="extWork" onChange={handleMisc} />}
                        label={
                          <Typography fontSize={14}>External Work</Typography>
                        }
                      />
                    </FormGroup>
                    <Typography
                      variant="h6"
                      fontSize={17}
                      className="config-labels"
                      marginY={2}
                    >
                      OPTIONAL SERVICES
                    </Typography>
                    {selectedPortfolio.optionalServices
                      ?.split(",")
                      .map((indOpService) => (
                        <Typography
                          sx={{ fontSize: 14, color: "#4f505b", my: 1 }}
                        >
                          {indOpService}
                        </Typography>
                      ))}
                    <Typography
                      variant="h6"
                      fontSize={17}
                      className="config-labels"
                      marginY={2}
                    >
                      FREE SERVICES
                    </Typography>
                    {selectedPortfolio.freeServices
                      ?.split(",")
                      .map((indFreeService) => (
                        <Typography
                          sx={{ fontSize: 14, color: "#4f505b", my: 1 }}
                        >
                          <span
                            style={{
                              backgroundColor: "#50ba49",
                              color: "#ffffff",
                              padding: 5,
                              marginRight: 7,
                              fontSize: 10,
                              borderRadius: 7,
                            }}
                          >
                            Free
                          </span>
                          {indFreeService}
                        </Typography>
                      ))}
                    <button className="btn bg-primary text-white mt-3" style={{ width: '100%' }} onClick={() => addPortfolioToCart()} >Add To Cart</button>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal.Body>
    </Modal>
  );
}

export const SearchLabel = (props) => {
  return (
    <Typography
      sx={{ borderRight: 1, borderRightColor: "#000000", pr: 2, fontSize: 13 }}
    >
      {props.children}
    </Typography>
  )
}
