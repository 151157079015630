import { Button, Card, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { equipment } from "../navigation/navigationConst";

export default function AddEquipments(props) {
    const { show, handleClose, handleAddEquipments } = props;
    const inputArr = [
        {
            equipmentNumber: {
                type: "text",
                id: 1,
                value: ""
            },
            modelNo: {
                type: "text",
                id: 1,
                value: ""
            }
        }


    ];

    const [arr, setArr] = useState(inputArr);

    const addInput = () => {
        if (arr[arr.length - 1]?.equipmentNumber.value) {

            setArr(s => {
                return [
                    ...s,
                    {
                        equipmentNumber: {
                            type: "text",
                            value: ""
                        }, modelNo: {
                            type: "text",
                            value: ""
                        }
                    }
                ];
            });
        }
    };
    const removeInput = () => {
        if (arr.length > 1) {
            setArr(arr.slice(0, -1))
        }
    };

    const handleChangeEquipment = e => {
        e.preventDefault();
        const index = e.target.id;
        setArr(s => {
            const newArr = s.slice();
            newArr[index].equipmentNumber.value = e.target.value;
            return newArr;
        });
    };
    const handleChangeModel = e => {
        e.preventDefault();
        const index = e.target.id;
        setArr(s => {
            const newArr = s.slice();
            newArr[index].modelNo.value = e.target.value;
            return newArr;
        });
    };


    return (
        <Modal
            className="tablerowmodal "
            style={{ padding: 'auto', margin: 'auto' }}
            show={show}
            onHide={() => handleClose()}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Body className="p-3">
                <Card variant='outlined' sx={{ p: 3, borderRadius: 5 }}>
                    <Typography variant="body1" fontWeight={500}>Add Equipments</Typography>
                    <div className="row align-items-center my-3">
                        {arr.map((item, i) => {
                            console.log(item);
                            return (
                                <div className="row col-md-10 my-2">
                                    <input
                                        onChange={handleChangeEquipment}
                                        value={item.equipmentNumber.value}
                                        id={i}
                                        type={item.equipmentNumber.type}
                                        placeholder={`Equipment ${i + 1}`}
                                        size="40"
                                        class="form-control border-radius-10 text-input mx-2"
                                        style={{ width: "50%" }}
                                    />
                                    <input
                                        onChange={handleChangeModel}
                                        value={item.modelNo.value}
                                        id={i}
                                        type={item.modelNo.type}
                                        placeholder={`Model ${i + 1}`}
                                        size="40"
                                        class="form-control border-radius-10 text-input"
                                        style={{ width: "45%" }}

                                    />
                                </div>

                            );
                        })}
                        {/* <div className="ml-3"> */}
                        <a href={undefined} className="btn bg-white text-primary py-1 border-primary px-2" style={{ height: 30, marginRight: 5, width: 50 }} onClick={addInput}>+</a>
                        <a href={undefined} className="btn bg-white text-primary py-1 border-primary px-2" style={{ height: 30, marginRight: 5, width: 50 }} onClick={removeInput}>-</a>
                        {/* </div> */}
                    </div>
                    <div className="row justify-content-end">
                        <Button
                            className="btn bg-primary text-white border"
                            style={{ height: 40 }}
                            onClick={handleAddEquipments}
                        >
                            Add Equipments
                        </Button>
                    </div>
                </Card>
            </Modal.Body>
        </Modal >
    )
}