import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DataGridContainer from "./common/DataGridContainer";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EquipmentQuoteAddEdit from "./EquipmentQuoteAddEdit";
import { fetchEquipments, fetchUsage } from "../services/EquipmentService";
import { SelfImprovement } from "@mui/icons-material";
const data = [
  {
    id: 0,
    make: "CATERPILLAR",
    model: "992K",
    dateOfPurchase: "29-04-2023",
    address: "KLS PHARMACEUTICALS LLP 158, FIRST FLOOR,SECTOR 21C",
    contracts: "EW6000",
    manufacturedYear: "2021",
    service: "PS75943",
  },
  {
    id: 1,
    make: "CATERPILLAR",
    model: "336D2",
    dateOfPurchase: "29-04-2023",
    address: "KLS PHARMACEUTICALS LLP 158, FIRST FLOOR,SECTOR 21C",
    contracts: "EW6000",
    manufacturedYear: "2021",
    service: "PS75943",
  },
  {
    id: 2,

    make: "CATERPILLAR",
    model: "C15",
    dateOfPurchase: "29-04-2023",
    address: "KLS PHARMACEUTICALS LLP 158, FIRST FLOOR,SECTOR 21C",
    contracts: "EW6000",
    manufacturedYear: "2021",
    service: "PS75943",
  },
];

const usageData = [
  {
    id: 0,
    currentUsage: "982991123",
    averageUsage: "New",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "9.92",
    smuType: "9.92",
  },
  {
    id: 0,
    currentUsage: "982991123",
    averageUsage: "New",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "9.92",
    smuType: "9.92",
  },
];

const EquipmentMaster = () => {
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentUsageData, setEquipmentUsageData] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const handleEquipmentModal = (modalType, edit) => {
    setShowModal(true);
    setEdit(edit);
    setModalType(modalType);
  };

  useEffect(() => {
    fetchEquipmentDetails();
  }, []);

  useEffect(() => {
    console.log("ABCD", selectionModel);
    if (selectionModel?.length > 0) {
      fetchUsage(selectionModel).then((result) => {
        console.log(result);
      });
    }
  }, [selectionModel]);
  const fetchEquipmentDetails = async () => {
    await fetchEquipments("currentClient:101211 AND tenantId:74")
      .then((equipments) => {
        console.log(equipments);
        setEquipmentData(equipments);
      })
      .catch((e) => console.log(e));
  };
  const equipmentsColumns = [
    {
      field: "maker",
      headerName: "Manufacturer",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ fontSize: 12 }}>{params.row.maker}</div>
      ),
    },
    { field: "model", headerName: "Model", flex: 1, minWidth: 120 },
    {
      field: "installationDate", //confirm
      headerName: "Date Of Purchase",
      flex: 1,
      minWidth: 120,
    },
    { field: "address", headerName: "Address", flex: 1, minWidth: 220 }, //not present
    {
      field: "contract",
      headerName: "Contracts/EW",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "yearOfManufacture",
      headerName: "Manufacturing Year",
      flex: 1,
      minWidth: 120,
    },
    { field: "service", headerName: "Service Program", flex: 1, minWidth: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon
                  onClick={() =>
                    handleEquipmentModal("equipmentTableModal", true)
                  }
                />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const usageColumns = [
    {
      field: "currentUsage",
      headerName: "Current Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "averageUsage",
      headerName: "Average Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "updateDate",
      headerName: "Last Updated Date",
      flex: 1,
      minWidth: 120,
    },
    { field: "sensorId", headerName: "Sensor ID", flex: 1, minWidth: 120 },

    { field: "smuId", headerName: "SMU ID", flex: 1, minWidth: 120 },
    {
      field: "smuType",
      headerName: "SMU Type",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon
                  onClick={() => handleEquipmentModal("usageTableModal", true)}
                />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4">
            <h4 className="font-weight-600 mb-0">Machines in Possession</h4>
            <a
              className="btn bg-primary text-white"
              onClick={() => handleEquipmentModal("equipmentProfile", false)}
            >
              <span>
                <AddIcon />
              </span>
              Add Equipment
            </a>
          </div>
          <DataGridContainer
            rowsData={equipmentData}
            columns={equipmentsColumns}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="font-weight-600 mb-0">Usage Details</h4>
            <a
              className="btn bg-primary text-white"
              onClick={() => handleEquipmentModal("usageProfile", false)}
            >
              <span>
                <AddIcon />
              </span>
              Add Usage
            </a>
          </div>
          <DataGridContainer
            rowsData={equipmentUsageData}
            columns={usageColumns}
          />
        </div>
      </div>
      {showModal && (
        <EquipmentQuoteAddEdit
          show={showModal}
          hideModal={() => setShowModal(false)}
          modalType={modalType}
          edit={edit}
        />
      )}
    </>
  );
};

export default EquipmentMaster;
